import { Box, Button, Card, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit';
import { DialogModal, DialogRef } from 'modules/modal/component/dialog.component'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import EnterpriseSelect from 'modules/enterprise/component/enterpriseSelect.component'
import { ICompany } from 'app/models/enterprise.model'
import { IMember } from 'app/models/user.model'
import DeleteConfirmButton from 'modules/modal/component/confirm_delete_button.component'
import { useOnLogin } from 'app/providers/onlogin.provider'
import { useMutation } from 'react-query'
import { deleteAttendee, updateAttendee } from 'app/api/booking.api'
import { IUpdateAttendee } from 'app/models/booking.model'

interface ParticipantsComponentProps {
  participants: { company: ICompany; member: IMember }[];
  onParticipantsChange: (participants: { company: ICompany; member: IMember }[]) => void;
  ownerDetail?: { firstname: string; lastname: string };
  isAnUpdate?: boolean;
  customerReservationId?: number;
  reservationIsCancelled?: boolean;
}

const ParticipantsComponent: React.FC<ParticipantsComponentProps> = ({
                                                                       participants,
                                                                       onParticipantsChange,
                                                                       ownerDetail,
                                                                       isAnUpdate = false,
                                                                       customerReservationId,
                                                                       reservationIsCancelled = false
                                                                     }) => {
  const { t } = useTranslation();
  const { currentEnterprise, currentEnterpriseName } = useOnLogin();
  const dialogRef = useRef<DialogRef>(null)
  const [company, setCompany] = useState<ICompany | null>(null);
  const [member, setMember] = useState<IMember | null>(null);
  const { account } = useOnLogin();
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if ( reservationIsCancelled || isAnUpdate && account?.firstname !== ownerDetail?.firstname && account?.lastname !== ownerDetail?.lastname) {
      setShowEditIcon(false);
    }
  }, [account, ownerDetail]);

  useEffect(() => {
    if (currentEnterprise) {
      setCompany({ id: currentEnterprise, name: currentEnterpriseName });
    }
    setIsDeleting(false);
  }, [currentEnterprise]);

  useEffect(() => {
    if (isAnUpdate && dialogRef.current?.isOpen && !isDeleting) {
      updateAttendees();
    }
  }, [participants, dialogRef])

  const addParticipant = () => {
    onParticipantsChange([{ company: company!, member: member! }, ...participants]);
    setMember(null);
  };

  const deleteParticipant = (memberId: number) => {
    setIsDeleting(true);
    deleteAttendees(memberId);
    onParticipantsChange(participants.filter(p => p.member.id !== memberId));
  };

  const handleEnterpriseSelect = (company: ICompany) => {
    setCompany(company);
  };

  const handleMembersSelect = (members: IMember) => {
    setMember(members);
  };

  const openModal = () => {
    dialogRef.current?.open();
  }

  const { mutate: updateAttendeeMutate } = useMutation<
    void,
    unknown,
    { customerReservationId: number; body: IUpdateAttendee }
  >((variables) => updateAttendee(variables.customerReservationId, variables.body), {
  });

  const { mutate: deleteAttendeeMutate } = useMutation<
    void,
    unknown,
    { customerReservationId: number; attendeeId: number }
  >((variables) => deleteAttendee(variables.customerReservationId, variables.attendeeId), {
  });

  const updateAttendees = () => {
    if (customerReservationId && participants[0].member && participants[0].company) {
      const body: IUpdateAttendee = {
        attendee: participants[0].member.id,
        enterprise: participants[0].company.id
      }
      updateAttendeeMutate({ 
        customerReservationId: customerReservationId, 
        body 
      });
    }
  };

  const deleteAttendees = (attendeeId: number) => {
    if (customerReservationId) {
      deleteAttendeeMutate({ 
        customerReservationId: customerReservationId, 
        attendeeId: attendeeId 
      });
    }
  }

  return (
    <Grid>
      <Box display={'flex'} alignItems={'center'}>
        <h4>{t('common.bookingView.bookingReadRightComponent.attendees')}</h4>
        {showEditIcon && (
          <EditIcon color="primary" style={{marginLeft: 10, maxWidth: '18px', maxHeight: '18px'}} onClick={openModal}/>
        )}
      </Box>
      {ownerDetail &&
        <Box display="flex" alignItems="center">
          <Typography style={{ marginRight: 5 }} variant="body2">
            {t('common.bookingView.bookingReadRightComponent.organized')}
          </Typography>
          <Typography variant="body2" color="primary">
            {ownerDetail?.firstname} {ownerDetail?.lastname}
          </Typography>
        </Box>
      }
      {participants.length > 0 && (
        <Box display={'flex'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
          <Typography style={{marginRight: 5}} variant="body2">{t('common.bookingView.bookingReadRightComponent.listAttendees')} ({participants.length}): </Typography>
          {participants.slice(0, 3).map((p, index) => (
            <Box key={index} style={{marginRight: index < 2 ? 10 : 0}}>
              <Typography variant="body2" color={'primary'}>{p.member.name}</Typography>
            </Box>
          ))}
          {participants.length > 3 && <Box><Typography variant="body2">...</Typography></Box>}
        </Box>
      )}
      <DialogModal
        ref={dialogRef}
        title={t('common.bookingView.bookingReadRightComponent.manageAttendees')}
        maxWidth="sm"
        actions={
          <>
          </>
        }
      >
        <Typography variant="subtitle2" style={{marginBottom:20}}>
          {t('common.bookingView.bookingReadRightComponent.addAttendees')}
        </Typography>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4}>
            <EnterpriseSelect
              onSelect={handleEnterpriseSelect}
              label="Entreprise"
              searchEnterprise={true}
              value={company}
              disabled={true}
              valueSelected={participants.map(p => p.company)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EnterpriseSelect
              onSelect={handleMembersSelect}
              label="Utilisateur"
              searchEnterprise={false}
              disabled={!company}
              enterpriseId={company?.id}
              value={member}
              valueSelected={participants.map(p => p.member)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              onClick={addParticipant}
              disabled={!company || !member}
              fullWidth
            >
              {t('common.bookingView.bookingReadRightComponent.add')}
            </Button>
          </Grid>
        </Grid>

        <Grid style={{marginTop: 20}}>
          <Typography variant="subtitle2" gutterBottom>
            {t('common.bookingView.bookingReadRightComponent.attendees')}
          </Typography>
        </Grid>
        <Card style={{marginTop:10}}>
          <Box>
            {participants.length === 0 ? (
              <Typography variant="subtitle1" align="center" style={{marginTop:10}}>
                {t('common.bookingView.bookingReadRightComponent.noBody')}
              </Typography>
            ) : (
              <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Entreprise</TableCell>
                      <TableCell>Utilisateur</TableCell>
                      <TableCell style={{ width: '100px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {participants.map((p, index) => (
                      <TableRow key={index}>
                        <TableCell>{p.company.name}</TableCell>
                        <TableCell>{p.member.name}</TableCell>
                        <TableCell style={{ width: '100px' }}>
                          <DeleteConfirmButton onConfirm={() => deleteParticipant(p.member.id)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Box>
        </Card>
      </DialogModal>
    </Grid>
  )
}

export default ParticipantsComponent