import * as React from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide
} from '@material-ui/core'
import { forwardRef } from 'react'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import IconButton from '@material-ui/core/IconButton'
import { Close } from '@material-ui/icons'

export type DialogRef = {
  open: () => void
  close: () => void
  isOpen: boolean
}
type DialogProps = {
  children: React.ReactNode
  title: string | React.ReactNode
  actions: React.ReactNode
  onClose?: () => void
  canDismiss?: boolean
  maxWidth?: Breakpoint
}

export const DialogModal = forwardRef<DialogRef, DialogProps>(
  (
    { children, title, actions, onClose, canDismiss = true, maxWidth = 'lg', ...otherProps },
    ref
  ) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => {
      setOpen(false)
      if (onClose) onClose()
    }

    React.useImperativeHandle(ref, () => ({
      open: handleClickOpen,
      close: handleClose,
      isOpen: open
    }))

    return (
      <Dialog
        maxWidth={maxWidth}
        fullWidth={true}
        {...otherProps}
        open={open}
        onClose={canDismiss ? handleClose : undefined}
        TransitionComponent={Slide}
        data-cy={'echance-dialog'}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {title}
            {canDismiss && (
              <IconButton size="small" onClick={handleClose}>
                <Close style={{ fontSize: 20 }} />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    )
  }
)
DialogModal.displayName = 'Dialog'
