import Session from 'app/libraries/session.lib'
import {IErrorResponse, IResponse} from 'app/models/api.model'

const getHeaders = (authentication: boolean): Headers => {
  const headers = new Headers()
  headers.append('Accept', 'application/json')
  headers.append("Content-Type", "application/json");

  if (authentication) {
    headers.append('Authorization', `Bearer ${Session.getToken()}`)
    headers.append('x-device-key', Session.getDeviceKey())
  }

  return headers
}

const resolver = async (response: IResponse, resolve: Function, reject: Function, errorMessage = false) => {
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 206:
      resolve(response.json())
      break
    case 204:
      resolve(undefined)
      break
    default: {
      let error: (number | IErrorResponse) = response.status

      if (errorMessage) {
        const jsonErrorResponse: {error: string, status: boolean} = await response.json()
        error = {
          error: jsonErrorResponse.error,
          status: jsonErrorResponse.status,
          code: response.status
        }
      }
      reject(error)
    }
  }
}

export const checkResponse = async (response: Response, errorMessage = false): Promise<any> => {
  return new Promise((resolve, reject) => {
    resolver(response as IResponse, resolve, reject, errorMessage)
  })
}

export const getUrl = (resource: string, encode?: boolean): RequestInfo => {
  if (undefined !== encode && encode) {
    return encodeURI(`${process.env.REACT_APP_API_URL}/${resource}`)
  }
  return `${process.env.REACT_APP_API_URL}/${resource}`
}

export const getRequestInit = (authentication: boolean): RequestInit => {
  return {
    method: 'GET',
    headers: getHeaders(authentication)
  }
}

export const postRequestInit = (authentication: boolean, body: BodyInit): RequestInit => {
  const headers = getHeaders(authentication)
  return {
    method: 'POST',
    headers: headers,
    body: body
  }
}

export const patchRequestInit = (authentication: boolean, body: BodyInit): RequestInit => {
  return {
    method: 'PATCH',
    headers: getHeaders(authentication),
    body: body
  }
}

export const deleteRequestInit = (authentication: boolean): RequestInit => {
  return {
    method: 'DELETE',
    headers: getHeaders(authentication)
  }
}
