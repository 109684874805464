import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IEnterprise } from 'app/models/enterprise.model'

export const getEnterpriseById = async (enterpriseId: number): Promise<IEnterprise> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterpriseId}`),
      getRequestInit(true)
    )
  );
};