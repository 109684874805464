import { checkResponse, getRequestInit, getUrl } from 'app/api/api'
import { IAttendee } from 'app/models/members.model'

export const getAttendeeByCustomerReservation = async (customerReservationId: number): Promise<IAttendee> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/meetings/attendees/${customerReservationId}`),
      getRequestInit(true)
    )
  );
};