import {checkResponse, getRequestInit, getUrl, patchRequestInit} from 'app/api/api'
import {IAccount, IPassword} from 'app/models/account.model'

export const AccountQuery = async (id: number): Promise<IAccount> => {
  return checkResponse(await fetch(getUrl(`accounts/${id}`), getRequestInit(true)))
}

export const AccountUpdateQuery = async (id: number, account: IAccount): Promise<IAccount> => {
  return checkResponse(
    await fetch(getUrl(`accounts/${id}`), patchRequestInit(true, JSON.stringify(account)))
  )
}

export const PasswordUpdateQuery = async (id: number, password: IPassword): Promise<IAccount> => {
  return checkResponse(
    await fetch(getUrl(`accounts/${id}/password`), patchRequestInit(true, JSON.stringify(password)))
  )
}

export const TokenCheckQuery = async (token: string): Promise<void> => {
  return checkResponse(
    await fetch(getUrl(`password_check?jwt=${token}`), getRequestInit(false))
  )
}