import { checkResponse, getRequestInit, getUrl } from './api'
import { IFetchEnterprise } from 'app/models/enterprise.model'
import { IFetchMembers } from 'app/models/user.model'

export const fetchEnterprise = async (search: string): Promise<IFetchEnterprise> => {
  return checkResponse(
    await fetch(getUrl(`clientspace/filter_clients?name=${search}&is_enterprise=true`), getRequestInit(true))
  )
};

export const fetchMembers = async (search: string, enterpriseId: number): Promise<IFetchMembers> => {
  return checkResponse(
    await fetch(getUrl(`clientspace/enterprises/${enterpriseId}/filter_members?name=${search}`), getRequestInit(true))
  )
};