import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { TextField, CircularProgress, MenuItem } from "@material-ui/core";
import Autocomplete, { AutocompleteInputChangeReason } from "@material-ui/lab/Autocomplete";
import { useMutation } from "react-query";
import { fetchMembers } from 'app/api/filters.api';
import { useTranslation } from 'react-i18next';
import { ICompany } from 'app/models/enterprise.model';
import { IFetchMembers, IMember } from 'app/models/user.model'

interface AsyncSelectProps {
  label?: string;
  onSelect: (company: ICompany) => void;
  searchEnterprise?: boolean;
  disabled?: boolean;
  enterpriseId?: number;
  valueSelected: IMember[] | ICompany[] | null;
  value?: ICompany | IMember | null;
}

const EnterpriseSelect: React.FC<AsyncSelectProps> = ({
                                                        label,
                                                        onSelect,
                                                        searchEnterprise = false,
                                                        enterpriseId = 0,
                                                        disabled = false,
                                                        valueSelected,
                                                        value
                                                      }) => {
  const [members, setMembers] = useState<IMember[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(value?.name || "");
  const { t } = useTranslation();

  useEffect(() => {
    setQuery(value?.name || "");
  }, [value]);

  const mutationFn = async (search: string): Promise<null | IFetchMembers> => {
    if (searchEnterprise) {
      return null;
    }
    return await fetchMembers(search, enterpriseId);
  };

  const { mutate } = useMutation(mutationFn, {
    onSuccess: (data) => {
      // @ts-ignore
      const parsedData = data?.[1]?.children?.map((item: any) => ({
        id: item.id,
        name: item.text,
      })) ?? [];
      if (!searchEnterprise) {
        setMembers(parsedData);
      }
      setLoading(false);
    },
  });

  const handleTyping = useCallback(
    (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
      setQuery(value);

      if (value.length > 0) {
        if (!searchEnterprise) {
          setLoading(true);
          mutate(value);
        }
      } else {
        setMembers([]);
      }
    },
    [mutate, searchEnterprise]
  );

  const filteredOptions = searchEnterprise
    ? []
    : members.filter(member => !valueSelected?.some(selected => selected.id === member.id));

  return (
    <Autocomplete
      disabled={disabled}
      options={filteredOptions}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        if (newValue) {
          setQuery(newValue.name);
          onSelect(newValue);
        }
      }}
      value={value}
      inputValue={query}
      onInputChange={handleTyping}
      loading={loading}
      noOptionsText={searchEnterprise ? t('common.bookingView.bookingReadRightComponent.noEnterprise') : t('common.bookingView.bookingReadRightComponent.noMembers')}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      )}
    />
  );
};

export default EnterpriseSelect;