import {RoleEnum, SectionEnum} from '../enums/app.enum'
import {IUser} from '../models/user.model'

export default class Session {
  public static setUser(user: IUser): void {
    localStorage.setItem('user', JSON.stringify(user))
  }

  public static getUser(): IUser|undefined {
    return JSON.parse(localStorage.getItem('user')!) ?? undefined
  }

  public static setCurrentEnterprise(id: number): void {
    localStorage.setItem('currentEnterprise', String(id))
  }

  public static setCurrentEnterpriseName(name: string): void {
    localStorage.setItem('currentEnterpriseName', name)
  }

  public static getCurrentEnterpriseName(): string {
    return localStorage.getItem('currentEnterpriseName')! ?? ''
  }

  public static getCurrentEnterprise(): number {
    return Number(localStorage.getItem('currentEnterprise')!) ?? 0
  }

  public static setCurrentRole(role: RoleEnum): void {
    localStorage.setItem('currentRole', String(role))
  }

  public static getCurrentRole(): RoleEnum {
    switch (localStorage.getItem('currentRole')) {
      case RoleEnum.ADMIN:
        return RoleEnum.ADMIN
      case RoleEnum.MANAGER:
        return RoleEnum.MANAGER
      default:
        return RoleEnum.USER
    }
  }

  public static setCurrentCenter(id: number): void {
    localStorage.setItem('currentCenter', String(id))
  }

  public static getCurrentCenter(): number {
    return Number(localStorage.getItem('currentCenter')!) ?? 0
  }

  public static setCurrentCenterName(id: string): void {
    localStorage.setItem('currentCenterName', id)
  }

  public static getCurrentCenterName(): string {
    return localStorage.getItem('currentCenterName')! ?? ''
  }

  public static setUserCenter(id?: number): void
  {
    if (undefined !== id) {
      localStorage.setItem('userCenter', String(id))
    }
  }

  public static getUserCenter(): number|undefined {
    return null === localStorage.getItem('userCenter') ? undefined : Number(localStorage.getItem('userCenter')!)
  }

  public static setUserCenterName(id?: string): void {
    if (undefined !== id) {
      localStorage.setItem('userCenterName', id)
    }
  }

  public static getUserCenterName(): string|undefined {
    return localStorage.getItem('userCenterName') ?? undefined
  }

  public static getToken(): string {
    return localStorage.getItem('token')! ?? ''
  }

  public static setToken(token: string): void {
    localStorage.setItem('token', token)
  }

  public static getDeviceKey(): string {
    return localStorage.getItem('DeviceKey')! ?? 'web'
  }

  public static getUserId(): number {
    const user = this.getUser()
    if (user !== undefined) {
      return user.id
    }
    return 0
  }

  public static getIndividualId(): number {
    const user = this.getUser()
    if (user !== undefined) {
      return user.individual_id
    }
    return 0
  }

  public static setIsDarkMode(isDarkMode: boolean): void {
    localStorage.setItem('isDarkMode', String(isDarkMode))
  }

  public static getIsDarkMode(): boolean {
    const item = localStorage.getItem('isDarkMode');
    return item !== undefined && item !== null ? (String(item).toLowerCase() === 'true' || Boolean(Number(item))) : false
  }

  public static setSection(section: SectionEnum): void {
    localStorage.setItem('section', String(section))
  }

  public static getSection(): SectionEnum {
    return Number(localStorage.getItem('section'))
  }

  public static clear(): void {
    localStorage.clear()
  }
}
