import {
  checkResponse,
  deleteRequestInit,
  getRequestInit,
  getUrl,
  postRequestInit
} from 'app/api/api'
import {
  IAttendeesCreate,
  IAvailabilityMeetingRoomQuery,
  IAvailabilityMeetingRoomResponse,
  ICommon,
  IContractServiceFromApi,
  ICustomerReservation,
  IMeetingRoomsQuery,
  IMeetingRoomsResponse,
  INomadeCounter,
  INomadeCounterForPricing,
  IReservation,
  IReservationForPricing,
  IScheduleDesktop,
  IService, IUpdateAttendee,
  MeetingSchema
} from 'app/models/booking.model'
import {formatParams} from "../helpers/common.helper";

export const MeetingServiceListQuery = async (center: number): Promise<IService[]> => {
  return checkResponse(
    await fetch(
      getUrl(`scheduledesktop/listmeetingservices?center=${center}`),
      getRequestInit(true)
    )
  )
}

export const CommonMeetingServiceListQuery = async (): Promise<IService[]> => {
  return checkResponse(
    await fetch(
      getUrl(`scheduledesktop/listcommonservicesformeetingroom`),
      getRequestInit(true)
    )
  )
}

export const CommonServiceListQuery = async (): Promise<ICommon[]> => {
  return checkResponse(
      await fetch(
          getUrl(`clientspace/common`),
          getRequestInit(true)
      )
  )
}

export const ScheduleDesktopListQuery = async (
  service: number,
  date: string,
  enterprise: number
): Promise<IScheduleDesktop[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/schedules?service=${service}&date=${date}`),
      getRequestInit(true)
    )
  )
}

export const CustomerReservationListQuery = async (
  enterprise: number,
  individualId: number
): Promise<ICustomerReservation[]> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/customerreservation?individualId=${individualId}`),
      getRequestInit(true)
    )
  )
}

export const PricingCustomerReservationQuery = async (
  enterprise: number,
  body: IReservationForPricing
): Promise<IReservation> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/pricer`),
      postRequestInit(true, JSON.stringify(body))
    )
  , true)
}

export const addAttendees = async (
  body: IAttendeesCreate,
): Promise<void> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/meetings/${body.attendees[0].customerReservation}/attendees`),
      postRequestInit(true, JSON.stringify(body))
    )
    , true)
}

export const updateAttendee = async (
  customerReservation: number,
  body: IUpdateAttendee,
): Promise<void> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/meetings/${customerReservation}/attendee`),
      postRequestInit(true, JSON.stringify(body))
    )
    , true)
}

export const deleteAttendee = async (
  customerReservationId: number,
  attendeeId: number,
): Promise<void> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/meetings/${customerReservationId}/attendees/${attendeeId}`),
      deleteRequestInit(true)
    )
    , true)
}

export const CreateCustomerReservationQuery = async (
  enterprise: number,
  body: IReservationForPricing
): Promise<IContractServiceFromApi> => {
  return checkResponse(
    await fetch(
      getUrl(`enterprises/${enterprise}/services`),
      postRequestInit(true, JSON.stringify(body))
    )
  , true)
}

export const DeleteContractServiceQuery = async (
  enterprise: number,
  id: number
): Promise<unknown> => {
  return checkResponse(
    await fetch(getUrl(`enterprises/${enterprise}/services/${id}`), deleteRequestInit(true))
  )
}

export const NomadeCounterQuery = async (
    enterprise: number,
    body: INomadeCounterForPricing
): Promise<INomadeCounter> => {
  return checkResponse(
      await fetch(
          getUrl(`enterprises/${enterprise}/nomadecounter?individual=${body.individualId}&center=${body.center}&date=${body.date}`),
          getRequestInit(true)
      )
  )
}

export const MeetingRoomsQuery = async (
    queryParams: IMeetingRoomsQuery
): Promise<IMeetingRoomsResponse> => {
  return checkResponse(
      await fetch(
          getUrl(`clientspace/meetings${formatParams(queryParams)}`),
          getRequestInit(true)
      )
  )
}

export const AvailabilityMeetingRoomQuery = async (
    id: number,
    queryParams: IAvailabilityMeetingRoomQuery
): Promise<IAvailabilityMeetingRoomResponse> => {
  return checkResponse(
      await fetch(
          getUrl(`clientspace/meetings/${id}/availability${formatParams(queryParams)}`),
          getRequestInit(true)
      )
  )
}

export const MeetingRoomQuery = async (
    id: number
): Promise<MeetingSchema> => {
  return checkResponse(
      await fetch(
          getUrl(`clientspace/meetings/${id}`),
          getRequestInit(true)
      )
  )
}
