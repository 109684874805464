import React, { useCallback, useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import {IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from '@material-ui/core'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {useTranslation} from 'react-i18next'

import CurrencyFormatter from 'app/formatters/currency.formatter'
import DateFormatter from 'app/formatters/date.formatter'
import {IContractServiceFromApi, ICustomerReservation} from 'app/models/booking.model'
import {useOnView} from 'app/providers/onview.provider'
import {useOnLogin} from 'app/providers/onlogin.provider'
import BookingListModalDeleteComponent from 'modules/booking/components/bookingList/bookingListModalDelete.component'
import {ServiceTypeEnum, StatusEnum} from "app/enums/booking.enum";
import BookingHelper from "app/helpers/booking.helper";
import ParticipantsComponent from 'modules/participants/components/participants.component'
import { ICompany } from 'app/models/enterprise.model'
import { IMember } from 'app/models/user.model'

export interface IProps {
  index: number
  openCollapseStatus: number[]
  customerReservation: ICustomerReservation
  setOpen: (index: number) => void
}

const BookingListItem = ({
                           index,
                           openCollapseStatus,
                           customerReservation,
                           setOpen
                         }: IProps): JSX.Element => {
  const {t} = useTranslation()
  const { account } = useOnLogin();
  const {currentEnterprise} = useOnLogin()
  const {deleteContractServiceUseMutation} = useOnView()

  const [isModalDelete, setIsModalDelete] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [selectedContractService, setSelectedContractService] = useState<
    IContractServiceFromApi | undefined
  >(undefined)
  const [messageModal, setMessageModal] = useState<string>(t('common.confirm_cancelling_service'))

  const onClickDelete = async (contractService: IContractServiceFromApi): Promise<void> => {
    setIsModalDelete(true)
    setSelectedContractService(contractService)
  }
  const [participants, setParticipants] = useState<{ company: ICompany; member: IMember }[]>([]);

  const handleParticipantsChange = (newParticipants: { company: ICompany; member: IMember }[]) => {
    setParticipants(newParticipants);
  };

  useEffect(() => {
    setParticipants(
    Array.isArray(customerReservation?.attendees)
      ? customerReservation.attendees.map((attendee) => ({
        company: {
          id: currentEnterprise,
          name: attendee.enterprise
        },
        member: {
          id: attendee.clientId,
          name: `${attendee.firstname} ${attendee.lastname}`
        }
      }))
      : []
    )
  }, [])

  const getDateToDisplay = (): string => {
    let message = '';
    let beginDate = customerReservation.beginDate
    let endDate = customerReservation.endDate

    if (beginDate !== undefined && endDate !== undefined) {
      let begin = new Date(beginDate)
      let end = new Date(endDate)
      if (end > begin) {
        message = t('common.from_date') + ' ' + DateFormatter.formatFrDateFromString(beginDate)
        if (customerReservation.beginHour !== undefined) {
          message += ' ' + customerReservation.beginHour
        }
        message += ' ' + t('common.to_date') + ' ' + DateFormatter.formatFrDateFromString(endDate)
        if (customerReservation.endHour !== undefined) {
          message += ' ' + customerReservation.endHour
        }
      } else {
        message = t('common.from_date') + ' ' + DateFormatter.formatFrDateFromString(beginDate)
        if (customerReservation.beginHour !== undefined) {
          message += ' ' + t('common.from') + ' ' + customerReservation.beginHour
        }
        if (customerReservation.endHour !== undefined) {
          message += ' ' + t('common.at') + ' ' + customerReservation.endHour
        }
      }
    }

    return message
  }

  const onValidate = useCallback(async (): Promise<void> => {
    if (selectedContractService !== undefined) {
      await deleteContractServiceUseMutation?.mutateAsync({
        enterpriseId: currentEnterprise,
        id: selectedContractService.id
      })
      setIsDelete(true)
      setMessageModal(t('common.service_has_been_cancelled'))
    }
  }, [currentEnterprise, selectedContractService])

  return (
    <>
      {isModalDelete && (
        <BookingListModalDeleteComponent
          open={isModalDelete}
          isDelete={isDelete}
          message={messageModal}
          onValidate={async () => await onValidate()}
          onClose={() => setIsModalDelete(false)}
        />
      )}
      <div className="card-list mb-20 bookingList" key={index}>
        <div
          onClick={() => setOpen(index)}
          aria-expanded={openCollapseStatus.includes(index)}
          className={
            openCollapseStatus.includes(index) ? 'card-list-header open' : 'card-list-header'
          }>
          <ExpandMoreIcon fontSize="medium" className="arrow"/>
          <div className="block-list icon">
            {customerReservation.status === StatusEnum.CREATED
              && <CheckCircleIcon className="statusIcon" data-toggle={"tooltip"}/>}
            {customerReservation.status === StatusEnum.CONFIRMED
              && <CheckCircleIcon className="confirmedIcon"/>}
            {customerReservation.status === StatusEnum.CANCELLED
              && <CancelIcon className="cancelledIcon"/>}
          </div>
          <div className="block-list date">
            <Typography variant="overline">
              {customerReservation.contractServices && customerReservation.contractServices.length > 0 && (
                <>
                  {customerReservation.contractServices[0].serviceType === ServiceTypeEnum.OPEN_DESKTOP
                    && t('common.booking_open_desktop')}
                  {customerReservation.contractServices[0].serviceType === ServiceTypeEnum.MEETING
                    && t('common.booking_meeting_room')}
                </>
              )}
              {( customerReservation.status !== 3  && (!customerReservation.contractServices || customerReservation.contractServices.length === 0)) && (
                <strong>{CurrencyFormatter.formatFr(customerReservation.amount)}</strong>
              )}
            </Typography>
            <Typography paragraph={true}>
              <strong>{customerReservation.center}</strong>
            </Typography>
          </div>
          <div className="block-list category">
            <Typography paragraph={true}>
              {getDateToDisplay()}
            </Typography>
            <Typography
              variant="overline"><strong>{t('common.booking')}&nbsp;{BookingHelper.getStatusLabel(customerReservation.status)}</strong></Typography>
          </div>
          <div className="fixFloat"></div>
        </div>
        <Collapse in={openCollapseStatus.includes(index)}>
          <div className="card-list-body">
            <List>
              {customerReservation.contractServices
                .filter((cs: IContractServiceFromApi) => cs.serviceType === 4)
                .map((contractService: IContractServiceFromApi, index: number) => {
                  return (
                    <ListItem key={index} dense={true} className="py-0">
                      <ListItemIcon>
                        <MeetingRoomIcon/>
                      </ListItemIcon>
                      <ListItemText
                        primary={contractService.serviceLabel}
                        secondary={
                          account?.firstname === customerReservation?.ownerDetails?.firstname &&
                          account?.lastname === customerReservation?.ownerDetails?.lastname ? (
                            t('common.booking_price') +
                            ': ' +
                            (contractService.reductionPrice > 0
                              ? CurrencyFormatter.formatFr(contractService.reductionPrice * 1.2)
                              : CurrencyFormatter.formatFr(contractService.price * 1.2))
                          ) : undefined
                        }
                      />
                      {contractService.isRemovable && (
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete">
                            <DeleteOutlineIcon
                              className="textColorPrimary"
                              onClick={() => onClickDelete(contractService)}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )
                })}
              {customerReservation.contractServices
                .filter((cs: IContractServiceFromApi) => cs.serviceType !== 4)
                .map((contractService: IContractServiceFromApi, index: number) => {
                  return (
                    <ListItem key={index} dense={true} className="py-0">
                      {customerReservation.contractServices[0].serviceType === ServiceTypeEnum.MEETING && (
                        <ListItemText
                          primary={
                            contractService.serviceLabel +
                            (contractService.quantity > 1 ? ' x ' + contractService.quantity : '')
                          }
                          secondary={
                            t('common.booking_price') +
                            ': ' +
                            (contractService.reductionPrice > 0
                              ? CurrencyFormatter.formatFr(contractService.reductionPrice * 1.2)
                              : CurrencyFormatter.formatFr(contractService.price * 1.2)) +
                            (contractService.fees > 0
                              ? ' | ' +
                              t('common.booking_fees') +
                              ': ' +
                              CurrencyFormatter.formatFr(contractService.fees)
                              : '')
                          }
                        />
                      )}
                      {customerReservation.contractServices[0].serviceType === ServiceTypeEnum.OPEN_DESKTOP && (
                        <ListItemText
                          primary={
                            t('common.booking_open_desktop')
                          }
                          secondary={
                            t('common.booking_open_desktop_communicated')
                          }
                        />
                      )}
                      {contractService.isRemovable && (
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete">
                            <DeleteOutlineIcon
                              className="textColorPrimary"
                              onClick={() => onClickDelete(contractService)}
                            />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  )
                })}
            </List>
            {customerReservation.comment !== undefined && customerReservation.comment !== '' && (
              <div className="block-message">
                <div className="block-message-header">{t('common.additional_needs')}</div>
                <div className="block-message-content">{customerReservation.comment}</div>
              </div>
            )}
          </div>
          <ParticipantsComponent
            participants={participants}
            onParticipantsChange={handleParticipantsChange}
            ownerDetail={{
              firstname: customerReservation?.ownerDetails.firstname,
              lastname: customerReservation?.ownerDetails.lastname
            }}
            isAnUpdate={true}
            customerReservationId={customerReservation.id}
          />
        </Collapse>
      </div>
    </>
  )
}

export default BookingListItem
