import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ClearIcon from '@material-ui/icons/Clear'

interface DeleteConfirmButtonProps {
  onConfirm: () => void;
}

const DeleteConfirmButton: React.FC<DeleteConfirmButtonProps> = ({ onConfirm }) => {
  const [confirming, setConfirming] = useState(false);

  return (
    <>
      {confirming ? (
        <>
          <IconButton onClick={() => setConfirming(false)}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              onConfirm();
              setConfirming(false);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={() => setConfirming(true)}>
          <ClearIcon />
        </IconButton>
      )}
    </>
  );
};

export default DeleteConfirmButton;
